<template>
  <StoreDialog
    :value="true"
    :time-zones="timeZones"
    @ok="onAddStore"
    @cancel="onCancelStore"
  />
</template>

<script>
import { TIME_ZONES_QUERY, STORE_CREATE } from "./graphql";
import store from "@/store";

export default {
  name: "StoreDialogView",
  components: {
    StoreDialog: () => import("./components/StoreDialog.vue"),
  },
  data: () => ({
    timeZones: [],
  }),
  apollo: {
    timeZones: {
      query: TIME_ZONES_QUERY,
      update: (data) => data.timeZones.map((x) => x.name),
      watchLoading(loading) {
        this.loading = loading;
      },
    },
  },
  methods: {
    async onAddStore({ values, setErrors }) {
      const response = await this.$apollo.mutate({
        mutation: STORE_CREATE,
        variables: {
          input: values,
        },
      });

      const data = response.data.storeCreate;

      if (data.errors?.length > 0) {
        setErrors(data.errors);
        return;
      }

      // Refresh auth to get new store employee data
      await store.dispatch("auth/refresh");

      await this.$router.push({
        name: "onboarding.welcome",
        params: { storeId: data.store.id },
      });
    },
    onCancelStore() {
      this.$router.back();
    },
  },
};
</script>
